import { TextInput as TextInputComponent } from '@mantine/core'

import styles from './input.module.scss'

declare module '@mantine/core' {
  export interface InputBaseProps {
    /**
     * Mark the input as edited with a light background
     */
    'data-dirty'?: boolean
  }

  export interface TextInputProps {
    /**
     * Mark the input as edited with a light background
     */
    'data-dirty'?: boolean
  }

  export interface NumberInputProps {
    /**
     * Mark the input as edited with a light background
     */
    'data-dirty'?: boolean
  }
}

export const TextInput = TextInputComponent.extend({
  defaultProps: {
    size: 'md',
  },
  classNames: {
    input: styles.textInput,
  },
})
