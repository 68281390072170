import React from 'react'

import { Group, Modal, Stack, Text } from '@mantine/core'
import { useElementSize, useInterval } from '@mantine/hooks'
import { observer } from 'mobx-react-lite'
import { RouteComponentProps } from 'wouter'

import { FaIcon } from '@components/FaIcon'
import { useMst, useNavigation } from '@hooks'

import { HistoryAndBody } from './HistoryAndBody'
import { SnapshotLandToolbar } from './toolbar'
import { useScriptData } from './useSnapshotLandData'

// how often to poll for new snapshots when the query is mounted and
// we're in the foreground
const CHECK_FOR_LATEST_INTERVAL_MS = 60 * 1000

const Title = ({ name }: { name?: string }) => {
  const text = name ? (
    <Text span>
      Snapshot history for&nbsp;
      <Text span fw="bold">
        {name}
      </Text>
    </Text>
  ) : (
    <Text span>Snapshot history</Text>
  )

  return (
    <Group gap={6}>
      <FaIcon icon="fa-clock-rotate-left" />
      {text}
    </Group>
  )
}

type SnapshotLandProps = RouteComponentProps<{ scriptId: string }>

export const SnapshotLand = observer<SnapshotLandProps>(({ params }) => {
  const { view, location } = useMst()
  const { navigate, changeSearch } = useNavigation()
  const scriptQuery = useScriptData(params.scriptId)
  const { ref: sizeRef, width } = useElementSize()
  const { mostRecentSnapshot } = view.snapshotLand

  React.useEffect(() => {
    view.snapshotLand.initializeForScript(params.scriptId)

    // when we leave snapshotLand, we remove all the snapshot
    // state from mst
    return view.snapshotLand.tearDownSnapshotLand
  }, [params.scriptId, view.snapshotLand])

  // Select the most recent snapshot when ?redirect=most-recent is set
  React.useEffect(() => {
    if (!mostRecentSnapshot) return

    const { queryParams } = location

    if (queryParams.redirect === 'most-recent') {
      const snap1 =
        !queryParams.snap1 && !queryParams.snap2
          ? mostRecentSnapshot.id
          : queryParams.snap1 ?? null

      const newParams = {
        ...queryParams,
        redirect: null,
        snap1,
      }

      location.updateQueryParams(newParams, { replace: true })
    }
  }, [mostRecentSnapshot, changeSearch, location])

  const interval = useInterval(() => {
    view.snapshotLand.checkForNewer(params.scriptId)
  }, CHECK_FOR_LATEST_INTERVAL_MS)

  React.useEffect(() => {
    interval.start()
    return interval.stop
  }, [interval])

  const exitSnapshotLand = () => {
    navigate(view.explorerState.lastScriptPath ?? `/scripts/${params.scriptId}`)
  }

  return (
    <Modal
      opened
      fullScreen
      variant="compact"
      data-fixed-height
      withinPortal={false}
      xOffset={20}
      yOffset={20}
      title={<Title name={scriptQuery.data?.script.name} />}
      onClose={exitSnapshotLand}
      overlayProps={{ backgroundOpacity: 1 }}
    >
      <Stack h="100%" ref={sizeRef} gap={0}>
        <SnapshotLandToolbar scriptId={params.scriptId} />
        <HistoryAndBody scriptId={params.scriptId} width={width} />
      </Stack>
    </Modal>
  )
})
