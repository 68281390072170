import {
  Button,
  Center,
  Group,
  Loader,
  Overlay,
  Paper,
  Stack,
  Text,
} from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { useMst } from '@state'
import { reloadWindow } from '@util'

const OFFLINE_EXPLANATION = `Your browser lost its connection to Scripto's servers.`

export const SleepOverlay = observer(function SleepOverlay() {
  const { appStatus } = useMst()
  if (!appStatus.suspended) {
    return null
  }

  const showOfflineMessage =
    appStatus.visibility === 'visible' &&
    appStatus.isDisconnected &&
    !appStatus.resuming

  return (
    <Overlay color="#000" backgroundOpacity={0.1} blur={5}>
      <Center h="100vh">
        {appStatus.resuming && <Loader />}
        {showOfflineMessage && (
          <Paper p="xl">
            <Stack gap="xl">
              <Text size="md">{OFFLINE_EXPLANATION}</Text>
              <Group justify="center">
                <Button onClick={() => reloadWindow()}>Reload</Button>
              </Group>
            </Stack>
          </Paper>
        )}
      </Center>
    </Overlay>
  )
})
