import { differenceInSeconds } from 'date-fns'
import { types } from 'mobx-state-tree'
import { nanoid } from 'nanoid'

import { offerUpdate } from '@components/Modals'
import { BaseModel } from '@state/models/BaseModel'
import { delay, reloadWindow } from '@util'
import { isUpdateAvailable } from '@util/checkVersion'

const launchTime = new Date()

const minToSec = (minutes: number) => minutes * 60

// In the background and connected: 30 minutes
const BACKGROUND_IDLE_SECONDS = minToSec(30)

// In the background: 2 minutes after disonnect
const BACKGROUND_DISCONNECT_GRACE_PERIOD_SECONDS = minToSec(2)

// In the foreground: 10 minutes after disconnect
const FOREGROUND_DISCONNECT_GRACE_PERIOD_SECONDS = minToSec(10)

// Just a buffer for UI that shows disconnected
const RECONNECT_GRACE_PERIOD_SECONDS = 5

type TimeListenerType = {
  intervalSeconds: number
  elapsedTicks: number
  cb: Callback
}

// some older browsers don't have UserActivation, so just
// pretend these users have been active
const getActivation = (): UserActivation => {
  return (
    navigator.userActivation ?? {
      isActive: true,
      hasBeenActive: true,
    }
  )
}

export const AppStatus = BaseModel.named('AppStatus')
  .props({
    // universal timer for the app. We update this once per second
    now: types.optional(types.Date, launchTime),
    visibility: types.optional(
      types.enumeration<DocumentVisibilityState>(['hidden', 'visible']),
      document.visibilityState,
    ),
    lastVisibilityChange: types.optional(types.Date, launchTime),
    lastUserInteraction: types.optional(types.Date, launchTime),
    interval: types.maybe(types.number),
    suspended: false,
    resuming: false,
    disconnectedSince: types.maybe(types.Date),
  })
  .views((self) => ({
    get debugSleep(): boolean {
      return self.rootStore.view.debugSleep
    },
    get backgroundIdleLimit(): number {
      return this.debugSleep ? 10 : BACKGROUND_IDLE_SECONDS
    },
    get backgroundDisconnectLimit(): number {
      return this.debugSleep ? 10 : BACKGROUND_DISCONNECT_GRACE_PERIOD_SECONDS
    },
    get foregroundDisconnectLimit(): number {
      return this.debugSleep ? 30 : FOREGROUND_DISCONNECT_GRACE_PERIOD_SECONDS
    },
  }))
  .views((self) => ({
    secondsSince(value: Date) {
      return differenceInSeconds(self.now, value)
    },

    get launchTime() {
      return launchTime
    },

    get visible() {
      return self.visibility === 'visible'
    },

    // this enables a grace period after foregrounded before we
    // show any
    get isForegroundDisconnected(): boolean {
      return (
        self.rootStore.socketManager.status !== 'connected' &&
        self.visibility === 'visible' &&
        this.secondsSince(self.lastVisibilityChange) >
          RECONNECT_GRACE_PERIOD_SECONDS
      )
    },

    get isDisconnected(): boolean {
      return self.rootStore.socketManager.status !== 'connected'
    },

    // we suspend the app if it's been in the background for too long or
    // if it's gotten disconnected for too long (with different thresholds for
    // foreground and background
    get shouldSuspend(): boolean {
      // in the background for too long
      if (self.visibility === 'hidden') {
        const backgroundAgeSeconds = this.secondsSince(
          self.lastVisibilityChange,
        )
        if (backgroundAgeSeconds > self.backgroundIdleLimit) {
          return true
        }
      }

      if (
        self.rootStore.loggedIn &&
        this.isDisconnected &&
        self.disconnectedSince
      ) {
        const gracePeriod = this.visible
          ? self.foregroundDisconnectLimit
          : self.backgroundDisconnectLimit
        return this.secondsSince(self.disconnectedSince) > gracePeriod
      }

      return false
    },

    checkForSuspend():
      | { shouldSuspend: false }
      | { shouldSuspend: true; reason: 'disconnected' | 'idle' } {
      // in the background for too long
      if (self.visibility === 'hidden') {
        const backgroundAgeSeconds = this.secondsSince(
          self.lastVisibilityChange,
        )
        if (backgroundAgeSeconds > self.backgroundIdleLimit) {
          return {
            shouldSuspend: true,
            reason: 'idle',
          }
        }
      }

      if (
        self.rootStore.loggedIn &&
        this.isDisconnected &&
        self.disconnectedSince
      ) {
        const gracePeriod = this.visible
          ? self.foregroundDisconnectLimit
          : self.backgroundDisconnectLimit
        if (this.secondsSince(self.disconnectedSince) > gracePeriod) {
          return {
            shouldSuspend: true,
            reason: 'disconnected',
          }
        }
      }

      return { shouldSuspend: false }
    },
  }))
  .extend(() => {
    const timeListeners: { [key: string]: TimeListenerType } = {}

    return {
      actions: {
        registerTimeListener: ({
          cb,
          intervalSeconds = 1,
        }: {
          cb: Callback
          intervalSeconds?: number
        }) => {
          const id = nanoid()
          timeListeners[id] = {
            cb,
            intervalSeconds,
            elapsedTicks: 0,
          }
          return id
        },
        removeTimeListener: (id: string) => {
          delete timeListeners[id]
        },
        updateListeners: () => {
          Object.values(timeListeners).forEach((tl) => {
            // increment the tick count on all listeners
            tl.elapsedTicks = tl.elapsedTicks + 1
            // when the interval is reached, fire the callback
            // and reset the interval seconds to 0
            if (tl.elapsedTicks >= tl.intervalSeconds) {
              tl.cb()
              tl.elapsedTicks = 0
            }
          })
        },
      },
    }
  })
  .actions((self) => ({
    async checkForUpdate(): Promise<{
      isAvailable: boolean
      version?: string
    }> {
      if (self.rootStore.view.isDebugEnabled('update')) {
        return { isAvailable: true, version: 'v1.2.3' }
      }

      return isUpdateAvailable().catch((e) => {
        self.log.error('error checking for update', e)
        return { isAvailable: false }
      })
    },
    updateNow() {
      self.now = new Date()
      self.environment.datadog.setAppStatus({
        backgroundAgeSeconds: self.secondsSince(self.lastVisibilityChange),
        activityAgeSeconds: self.secondsSince(self.lastUserInteraction),
      })

      if (self.isDisconnected && !self.disconnectedSince) {
        self.disconnectedSince = self.now
      } else if (!self.isDisconnected) {
        self.disconnectedSince = undefined
      }

      const suspendStatus = self.checkForSuspend()
      if (suspendStatus.shouldSuspend) {
        this.suspendApp()
        self.log.info('suspending app', {
          visibility: self.visibility,
          cause: suspendStatus.reason,
        })
      }
    },
    handleUserActivity() {
      self.lastUserInteraction = new Date()
    },
    setVisibility(value: DocumentVisibilityState) {
      if (value === 'visible' && self.suspended) {
        this.setResuming(true)
      }
      self.visibility = value
      self.lastVisibilityChange = new Date()
      self.environment.datadog.setVisibility(value)
    },
    runClockTick() {
      this.updateNow()
      self.updateListeners()
      if (getActivation().isActive) {
        this.handleUserActivity()
      }
    },
    startInterval() {
      if (self.interval === undefined) {
        self.interval = window.setInterval(this.runClockTick, 1000)
      }
      this.runClockTick()
    },
    setResuming(value: boolean) {
      self.resuming = value
    },
    setSuspended(value: boolean) {
      self.suspended = value
      self.resuming = false
    },
    suspendApp() {
      if (!self.suspended) {
        this.setSuspended(true)
        if (self.interval !== undefined) {
          clearInterval(self.interval)
          self.interval = undefined
        }
        self.rootStore.socketManager.disconnect()
      }
    },

    /*
      If the app went to sleep, we want to reload the browser so
      we don't have to try to catch up on a zillion socket events, etc.

      However, if the user is offline, we want to give them a heads up
      before forcing a reload, so this checks if we're offline before
      doing a reload (see the sleep status UI)
    */
    async reloadAppIfOnline(): Promise<void> {
      this.setResuming(true)
      if (!navigator.onLine) {
        // artificial delay- without this user would think we didn't
        // check and hammer the delay button
        await delay(2)
        this.setSuspended(true)
      } else {
        reloadWindow()
      }
    },
    async requestReload() {
      self.log.info('reload requested after disconnect')
      return this.reloadAppIfOnline()
    },
  }))
  .actions((self) => ({
    afterAttach: () => {
      self.startInterval()

      document.addEventListener('visibilitychange', async () => {
        const { visibilityState } = document
        self.setVisibility(visibilityState)

        if (visibilityState === 'visible') {
          // did we just get foregrounded after suspension in the background?
          if (self.suspended) {
            self.log.info('visible after suspended')
            self.reloadAppIfOnline()
          } else {
            const { isAvailable, version } = await self.checkForUpdate()
            if (isAvailable) {
              offerUpdate(version)
            }
          }
        }
      })

      // if the user is scrolling with a trackpad or mouse wheel,
      // the navigator.userActivation doesn't update, so this catches that
      // case.
      document.addEventListener(
        'scrollend', // 'scroll' fires continuously so we use 'scrollend' instead
        () => self.handleUserActivity(),
        true,
      )
      self.setVisibility(document.visibilityState)
    },
  }))
