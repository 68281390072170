import React from 'react'

import {
  ActionIcon,
  Box,
  Card,
  Divider,
  Group,
  Stack,
  Text,
  TextInput,
  UnstyledButton,
} from '@mantine/core'
import cn from 'classnames'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import { showError } from '@components/Modals'
import { useMst } from '@hooks'
import { SnapshotSummary } from '@util/ScriptoApiClient/types'

import * as helpers from './helpers'

import styles from './SnapshotCard.module.scss'

export type SelectionMode = 'solo' | 'comparison'

// when to show the edit button
type EditButtonMode = 'hover' | 'always'

type SnapshotCardProps = {
  data?: SnapshotSummary
  sticky?: boolean
  selected?: boolean
  onClick?: () => void
  onClose?: () => void
  icon?: React.ReactNode
  enableClick?: boolean
  loading?: boolean
  error?: boolean
  editButton?: EditButtonMode
}

export const SnapshotCard = observer(function SnapshotCard(
  props: SnapshotCardProps,
) {
  const {
    data,
    onClick,
    onClose,
    selected,
    sticky,
    icon,
    enableClick,
    editButton,
  } = props
  const { view } = useMst()
  const [isEditing, setIsEditing] = React.useState(false)
  const [isSaving, setIsSaving] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')

  const classes = cn(styles.snapshotCard, {
    [styles.clickable]: enableClick && !selected,
    [styles.active]: selected,
    [styles.sticky]: sticky,
    [styles.editButtonHover]: editButton === 'hover',
    [styles.isEditing]: isEditing,
  })

  const manual = !!data?.name
  const title = helpers.cardTitle({ data })

  const startEditing = () => {
    if (data && !isEditing) {
      setInputValue(data.name ?? '')
      setIsEditing(true)
    }
  }

  const completeEditing = async () => {
    if (data && !isSaving) {
      if (inputValue.trim().length > 0 && inputValue !== data.name) {
        setIsSaving(true)
        try {
          await view.snapshotLand.renameSnapshot({
            snapshotId: data.id,
            scriptId: data.scriptId,
            name: inputValue,
          })
        } catch {
          showError('Failed to rename snapshot')
        } finally {
          setIsSaving(false)
        }
      }
    }
    setIsEditing(false)
  }

  return (
    <Card className={classes} onClick={enableClick ? onClick : undefined}>
      <Group gap={0} justify="space-between" wrap="nowrap">
        <Stack gap={0} className={styles.cardContent}>
          <Group
            w="100%"
            justify="space-between"
            align="baseline"
            wrap="nowrap"
          >
            <Box flex={1} miw={0}>
              {isEditing ? (
                <TextInput
                  autoFocus
                  size="xs"
                  onFocus={(event) => event.target.select()}
                  onBlur={completeEditing}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      completeEditing()
                    } else if (e.key === 'Escape') {
                      setIsEditing(false)
                    }
                  }}
                  data-mantine-stop-propagation
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  disabled={isSaving}
                />
              ) : (
                <Group gap={6} wrap="nowrap">
                  {icon}
                  {/* show title row if not editing */}
                  <Text
                    style={{ wordBreak: 'break-all' }}
                    lineClamp={1}
                    fw={manual ? 'bold' : undefined}
                  >
                    {title}
                  </Text>
                  {editButton && !isEditing && (
                    <UnstyledButton
                      className={styles.editButton}
                      onClick={(e) => {
                        e.stopPropagation()
                        startEditing()
                      }}
                    >
                      <FaIcon
                        icon="fa-pen-to-square"
                        size="14"
                        className={styles.editIcon}
                      />
                    </UnstyledButton>
                  )}
                </Group>
              )}
            </Box>
            <Text c="dimmed" size="xs">
              {data ? format(data.createdAt, 'h:mm a, M/d') : null}
            </Text>
          </Group>
          {data && !data.autoSave && (
            <Text style={{ wordBreak: 'break-all' }} lineClamp={1}>
              by {data.creatorName}
            </Text>
          )}
        </Stack>
        {!!onClose && (
          <Group gap={5} ml={10}>
            <Divider orientation="vertical" />
            <ActionIcon onClick={onClose}>
              <FaIcon c="dark.9" icon="fa-xmark" size="14" />
            </ActionIcon>
          </Group>
        )}
      </Group>
    </Card>
  )
})

export const ChooseSnapshotCard = ({ compare }: { compare?: boolean }) => (
  <Card padding="sm" className={styles.chooseSnapshotCard}>
    <Text lineClamp={2}>
      {compare ? 'Choose a snapshot to compare' : 'Choose a snapshot'}
    </Text>
  </Card>
)
