import { Checkbox as CheckboxComponent } from '@mantine/core'

import styles from './checkbox.module.scss'

declare module '@mantine/core' {
  export interface CheckboxProps {
    /**
     * Mark the checkbox container as edited with a light background
     */
    'data-dirty'?: boolean
  }
}

export const Checkbox = CheckboxComponent.extend({
  classNames: styles,
})
