import {
  ActionIcon,
  Menu,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
} from '@mantine/core'

import { BLACKISH_TEXT } from '@theme/colors'

import styles from './ColorTextInput.module.scss'

const HEX_RE = /^#([0-9A-F]{3}){1,2}$/i

export const isHex = (color: string) => HEX_RE.test(color)

export const ColorTextInput = ({
  color,
  onChange,
  onSubmit,
  defaultToBlack = false,
  showPreview = false,
  showSwatch = false,
  dirty = false,
}: {
  color: string
  defaultToBlack?: boolean
  showPreview?: boolean
  showSwatch?: boolean
  dirty?: boolean

  onChange: (val: string) => void
  onSubmit: (val: string) => void
}) => {
  const handleSubmit = (value: string) =>
    isHex(value) ? onSubmit(value) : onSubmit('')

  const displayColor = defaultToBlack ? color || BLACKISH_TEXT : color
  const previewColor = color || (defaultToBlack ? BLACKISH_TEXT : 'transparent')

  return (
    <TextInput
      classNames={{ input: styles.colorTextInput }}
      value={displayColor}
      onChange={(e) => {
        const { value } = e.target
        if (value !== '') onChange(e.target.value)
      }}
      maxLength={7}
      placeholder="e.g. #f0f"
      onKeyUp={(e) => {
        if (e.code === 'Enter') {
          handleSubmit(e.currentTarget.value)
        }
      }}
      error={
        !isHex(displayColor) && displayColor !== '' && 'e.g. #f00, #ff0000'
      }
      leftSection={
        showSwatch && (
          <Menu.Target>
            <ActionIcon
              className={styles.colorTextInput_hexPreview}
              bg={previewColor}
              size={20}
            />
          </Menu.Target>
        )
      }
      rightSection={
        showPreview && (
          <ThemeIcon
            className={styles.colorTextInput_hexPreview}
            color={previewColor}
            size={15}
            radius="xl"
          />
        )
      }
      onBlur={(e) => {
        handleSubmit(e.target.value)
      }}
      data-dirty={dirty}
    />
  )
}

export const ColorTextInputWithPreview = ({
  label,
  color,
  defaultToBlack = false,
  onChange,
  onSubmit,
}: {
  label: string
  color: string
  defaultToBlack?: boolean
  onChange: (val: string) => void
  onSubmit: (val: string) => void
}) => (
  <Stack gap={2}>
    <Text fw="bold" c="gray.7" size="sm">
      {label}
    </Text>
    <ColorTextInput
      defaultToBlack={defaultToBlack}
      showPreview
      color={color}
      onChange={onChange}
      onSubmit={onSubmit}
    />
  </Stack>
)
