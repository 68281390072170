import { OrgPermissionMap, ScriptStatusMap } from '@showrunner/codex'

import { showMoveToFolder } from '@components/Modals'
import { launchExplorerToast } from '@components/Toast'
import {
  IFolder,
  IListing,
  IRundownListing,
  IScriptListing,
  isScriptListing,
  IUser,
} from '@state/types'
import { capitalize } from '@util'
import { SHARE_SCRIPT_WARNING_MESSAGE } from '@util/constants'

import { DotDotDotItem } from './DotDotDotMenu'

export const MENU_ITEMS: { [key: string]: DotDotDotItem } = {
  copylink: {
    id: 'copylink',
    label: 'Copy link',
    icon: 'fa-link',
  },
  rename: {
    id: 'rename',
    label: 'Rename',
    icon: 'fa-edit',
  },
  move: {
    id: 'move',
    label: 'Move',
    icon: 'fa-arrow-right',
  },
  duplicate: {
    id: 'duplicate',
    label: 'Duplicate',
    icon: 'fa-copy',
  },
  trash: {
    id: 'trash',
    label: 'Trash',
    icon: 'fa-trash',
    type: 'danger',
  },
  restore: {
    id: 'restore',
    label: 'Restore',
    icon: 'fa-arrow-right',
  },
  destroy: {
    id: 'destroy',
    label: 'Delete Permanently',
    icon: 'fa-minus-circle',
    type: 'danger',
  },
  share: {
    id: 'share',
    label: 'Share',
    icon: 'fa-share',
  },
  addFavorite: {
    id: 'addFavorite',
    label: 'Add to My Favorites',
    icon: 'fa-star',
  },
  removeFavorite: {
    id: 'removeFavorite',
    label: 'Remove from My Favorites',
    icon: 'unfavorite',
  },
}

export const STANDARD_FOLDER_ITEMS = [
  // suppressing this until we have a good place to send users
  MENU_ITEMS.copylink,
  MENU_ITEMS.rename,
  MENU_ITEMS.move,
  MENU_ITEMS.trash,
]
export const TRASH_FOLDER_ITEMS = [MENU_ITEMS.restore]

const getTrashItems = (
  readonlyUser: boolean,
  cannotDestroy: boolean,
): DotDotDotItem[] => [
  {
    ...MENU_ITEMS.restore,
    disabled: readonlyUser,
  },
  {
    ...MENU_ITEMS.destroy,
    disabled: cannotDestroy,
  },
]

const getNonTrashItems = ({
  isPrivate,
  readonlyUser,
}: {
  isPrivate: boolean
  readonlyUser: boolean
}): DotDotDotItem[] => {
  const result: DotDotDotItem[] = isPrivate ? [MENU_ITEMS.share] : []
  result.push(
    MENU_ITEMS.copylink,
    { ...MENU_ITEMS.rename, disabled: readonlyUser },
    { ...MENU_ITEMS.move, disabled: readonlyUser },
    MENU_ITEMS.duplicate,
    { ...MENU_ITEMS.trash, disabled: readonlyUser },
  )

  return result
}

export const getScriptListingMenuItems = ({
  scriptListing,
  user,
}: {
  scriptListing: IScriptListing
  user: IUser
}): DotDotDotItem[] => {
  const readonlyUser =
    scriptListing.accessLevel === ScriptStatusMap.LIMITED &&
    !user.canEditLimited

  const canDestroy =
    scriptListing.accessLevel === ScriptStatusMap.PRIVATE ||
    user.selectedMembership?.permissions.includes(
      OrgPermissionMap.DESTROY_CONTENT,
    )

  const favoriteItem = scriptListing.isFavorite
    ? MENU_ITEMS.removeFavorite
    : MENU_ITEMS.addFavorite

  const otherItems = scriptListing.inTrash
    ? getTrashItems(readonlyUser, !canDestroy)
    : getNonTrashItems({
        isPrivate: scriptListing.isPrivate,
        readonlyUser,
      })

  return [favoriteItem, ...otherItems]
}

export const getRundownListingMenuItems = ({
  rundownListing,
  user,
}: {
  rundownListing: IRundownListing
  user: IUser
}): DotDotDotItem[] => {
  const favoriteItem = rundownListing.isFavorite
    ? MENU_ITEMS.removeFavorite
    : MENU_ITEMS.addFavorite

  const readonlyUser = !user.currentOrgPermissions.includes(
    OrgPermissionMap.UPDATE_RUNDOWNS,
  )

  if (rundownListing.inTrash) {
    return [favoriteItem, { ...MENU_ITEMS.restore, disabled: readonlyUser }]
  }

  const menuItems = [favoriteItem, MENU_ITEMS.copylink]

  ;[
    MENU_ITEMS.move,
    MENU_ITEMS.rename,
    MENU_ITEMS.duplicate,
    MENU_ITEMS.trash,
  ].forEach((i) => menuItems.push({ ...i, disabled: readonlyUser }))

  return menuItems
}

type MoveOperation = 'move' | 'share' | 'restore'
const moveModalStrings = (
  listing: IListing,
  operation: MoveOperation,
): {
  title: string
  failureMessage: string
  successMessage: string
  warningMessage?: string
} => {
  const docTypeName = isScriptListing(listing) ? 'script' : 'rundown'

  const title = `${capitalize(operation)} ${docTypeName}`
  const failureMessage = `Could not ${operation} ${docTypeName}`
  const warningMessage =
    operation === 'share' ? SHARE_SCRIPT_WARNING_MESSAGE : undefined

  const successMessage = `${capitalize(operation)}d "${
    listing.name
  }" successfully.`

  return {
    title,
    failureMessage,
    successMessage,
    warningMessage,
  }
}

const getTargetRootFolder = (
  listing: IListing,
  operation: MoveOperation,
): IFolder | undefined => {
  const { rootFolders } = listing.rootStore
  const isPrivate = isScriptListing(listing) ? listing.isPrivate : false

  if (operation === 'move' || operation === 'restore') {
    return isPrivate
      ? rootFolders.privateDashboard
      : rootFolders.sharedDashboard
  }

  if (operation === 'share') {
    return rootFolders.sharedDashboard
  }
}

export const launchMoveDocModal = ({
  listing,
  operation,
  isCurrentScript,
}: {
  listing: IListing
  operation: MoveOperation
  isCurrentScript?: boolean
}) => {
  const { title, failureMessage, warningMessage, successMessage } =
    moveModalStrings(listing, operation)
  const rootFolder = getTargetRootFolder(listing, operation)
  if (!rootFolder) {
    return
  }

  const folderListState =
    listing.rootStore.view.initializeReadonlyFolderState(rootFolder)

  const handleMoveDocToFolder = async (folderId: string, message: string) => {
    const isShare = operation === 'share'

    isShare
      ? await (listing as IScriptListing).share(folderId)
      : await listing.moveToFolder(folderId)

    // dont display a toast when the current script is shared
    // because our janky system alert blocks the thread mid-animation
    const sharingCurrentScript = isShare && isCurrentScript
    const restoringCurrentScript = operation === 'restore' && isCurrentScript

    if (!sharingCurrentScript) {
      launchExplorerToast({
        message,
        type: 'success',
        autoCloseDelayMs: 3000,
      })
    }

    // if the active script was plucked from the trash, make it editable again
    if (restoringCurrentScript) {
      listing.rootStore.currentScript?.setPmEditability(true)
    }
  }

  showMoveToFolder({
    title,
    itemName: listing.name,
    failureMessage,
    warningMessage,
    folderListState,
    onSubmit: (folderId) => handleMoveDocToFolder(folderId, successMessage),
  })
}
