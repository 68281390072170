import {
  ActionIcon,
  Code,
  CopyButton as MantineCopyButton,
} from '@mantine/core'

import { FaIcon } from '@components/FaIcon'

export const CopyButton = ({ text }: { text: string }) => (
  <MantineCopyButton value={text}>
    {({ copied, copy }) => (
      <ActionIcon variant="subtle" onClick={copy}>
        <FaIcon icon={copied ? 'fa-check' : 'fa-copy'} />
      </ActionIcon>
    )}
  </MantineCopyButton>
)

export const CopyableId = ({ id }: { id: string }) => (
  <>
    <Code>{id.slice(0, 7)}</Code>
    <CopyButton text={id} />
  </>
)
