import { format, isThisYear, isToday, isYesterday } from 'date-fns'

import { SnapshotSummary } from '@util/ScriptoApiClient/types'

const autoSaveStr = (snapshot: SnapshotSummary) => {
  // traps for the edge case here
  // https://github.com/showrunner/models/blob/d5fbad213952f0fbb8c35d406586f8cd2308f7ba/lib/dashboard-template-script.js#L76
  switch (snapshot.autoSave) {
    // see https://github.com/showrunner/wallaby/pull/3468 for more info
    case 'session':
      return 'Autosaved backup'
    case 'export':
    case 'prompter':
    case 'rundown':
      return 'Exported'
    case 'copied':
      return 'Copied'
    case 'pushToPrompter':
      return 'Pushed to prompter'
    default:
      return 'Autosaved backup'
  }
}
export const snapshotAuthor = (snapshot: SnapshotSummary) => {
  const known = `by ${snapshot.creatorName} `
  switch (snapshot.autoSave) {
    // backups and sessions arent attributed to a user
    case 'session':
    case 'backup':
      return ''
    default:
      return known
  }
}

export const cardTitle = ({
  data,
  isLoading,
  isError,
}: {
  isLoading?: boolean
  isError?: boolean
  data?: SnapshotSummary | null
}) => {
  if (isLoading) {
    return 'Loading...'
  }
  if (isError) {
    return 'Error loading snapshot'
  }

  if (data?.name) {
    return data.name
  }

  return data ? autoSaveStr(data) : 'Snapshot'
}

export const headerDateStr = (snapshot: SnapshotSummary) => {
  const date = new Date(snapshot.createdAt)
  return formatDate(date).text
}

const formatDate = (date: Date) => {
  if (isToday(date)) {
    return { kind: 'same-day', text: 'Today' }
  }

  if (isYesterday(date)) {
    return { kind: 'yesterday', text: 'Yesterday' }
  }

  if (isThisYear(date)) {
    return { kind: 'same-year', text: format(date, 'MMMM d') }
  }

  return { kind: 'before-this-year', text: format(date, 'MMMM d, yyyy') }
}

// Here's a helper that formats a date according to the Snapshot Rules:
// - If a timestamp is from today: just show the time
// - If it's between now and the calendar year: show a yearless date and time
// - If it's from before this year: just show the date with the year
// - If a user created the snapshot explicitly, add attribution
export const subtitle = (
  snapshot: SnapshotSummary,
  fullDate?: boolean,
): string => {
  const snapshotCreationDate = new Date(snapshot.createdAt)
  const timeparts = []
  const formattedDate = formatDate(snapshotCreationDate)

  if (formattedDate.kind === 'same-day') {
    timeparts.push('at', format(snapshotCreationDate, 'p'))
    if (fullDate) timeparts.push('today')
  } else if (formattedDate.kind === 'same-year') {
    timeparts.push('on', formattedDate.text)
    timeparts.push('at', format(snapshotCreationDate, 'p'))
  } else if (formattedDate.kind === 'before-this-year') {
    timeparts.push('on', formattedDate.text)
  }

  // autosaved backups are not attributed
  // autosaves from pdf exports, prompter pushes etc. are
  if (snapshot.creatorName && snapshot.autoSave !== 'backup') {
    timeparts.unshift(`by ${snapshot.creatorName}`)
  }

  return timeparts.join(' ')
}

export type SleneItem = { label: string; id: string }

export type BlankSide = {
  side: LeftRight
  reason: 'loading' | 'pickAnother'
}
