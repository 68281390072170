import { getRoot, type Instance, types } from 'mobx-state-tree'

import { BaseModel } from '@state/models/BaseModel'
import { IRoot } from '@state/types'
import {
  getStytchClient,
  IStytchClient,
  StytchAuthenticateResponse,
  StytchDeletePhoneNumberResponse,
  StytchSendOtpResponse,
} from '@util/stytch'

const StytchPhoneNumber = types
  .model('PhoneNumber', {
    phoneId: types.string,
    phoneNumber: types.string,
    verified: types.boolean,
  })
  .views((self) => ({
    get obfuscatedNumber() {
      const prefix = self.phoneNumber.slice(0, 2)
      const suffix = self.phoneNumber.slice(-4)
      const xs = new Array(self.phoneNumber.length - 6).fill('x').join('')
      return prefix + ' ' + xs + ' ' + suffix
    },
  }))
  .actions((self) => ({
    async sendOneTimePasscode(
      method: 'sms' | 'whatsapp',
    ): Promise<StytchSendOtpResponse> {
      const { stytch } = getRoot<IRoot>(self).authManager
      const r = await stytch.client.sendOtp(self.phoneNumber, method)
      stytch.getStytchUser()
      return r
    },
    async delete(): Promise<StytchDeletePhoneNumberResponse> {
      const { stytch } = getRoot<IRoot>(self).authManager
      const r = await stytch.client.deletePhoneNumber(self.phoneId)
      stytch.getStytchUser()
      return r
    },
  }))

const StytchUser = types.model('StytchUser', {
  userId: types.string,
  phoneNumbers: types.array(StytchPhoneNumber),
})

export const StytchMrManager = BaseModel.named('StytchMrManager')
  .volatile<{
    client: IStytchClient
  }>((self) => ({
    client: getStytchClient(self.environment.config.STYTCH_PUBLIC_TOKEN),
  }))
  .props({
    stytchUser: types.maybeNull(StytchUser),
  })
  .views((self) => ({
    get mfaEnabled(): boolean {
      return self.stytchUser?.phoneNumbers.some((p) => p.verified) ?? false
    },
    get verifiedPhone(): Instance<typeof StytchPhoneNumber> | undefined {
      return self.stytchUser?.phoneNumbers.find((p) => p.verified)
    },
  }))
  .actions((self) => ({
    getStytchUser() {
      const rawUser = self.client.getUserSync()
      if (rawUser) {
        self.stytchUser = StytchUser.create({
          userId: rawUser.user_id,
          phoneNumbers: rawUser.phone_numbers.map((phone) => ({
            phoneId: phone.phone_id,
            phoneNumber: phone.phone_number,
            verified: phone.verified,
          })),
        })
      } else {
        self.stytchUser = null
      }
    },

    async verifyOneTimePasscode(
      otp: string,
      oneTimePasscodeId: string,
    ): Promise<StytchAuthenticateResponse> {
      const r = await self.client.authenticateWithOtp(otp, oneTimePasscodeId)
      this.getStytchUser()
      return r
    },

    async authenticateWithPassword(args: {
      email: string
      password: string
    }): Promise<StytchAuthenticateResponse> {
      const r = await self.client.authenticateWithPassword(args)
      this.getStytchUser()
      return r
    },
  }))
