import React from 'react'

import { useForm } from 'react-hook-form'

import { OrgOption, OrgOptionMap } from '@showrunner/codex'

import { showAlert } from '@components/Modals'
import { useMst } from '@state'
import { DatadogClient } from '@util/datadog'
import { extractTsRestSuccess } from '@util/extractTsRest'
import { WorkspaceDetailsPayload } from '@util/ScriptoApiClient/types'

type Inputs = Partial<Record<OrgOption, boolean>>

const ddLog = DatadogClient.getInstance()

const hasOption = (
  workspace: WorkspaceDetailsPayload,
  option: OrgOption,
): boolean => {
  return !!workspace.options.find(({ code }) => code === option)?.enabled
}

const { PROMPTER_INTEGRATION, SCRIPT_LIMITING, RUNDOWNS, PRIVATE_SCRIPTS } =
  OrgOptionMap

export function PlanOptionsForm({
  workspace,
}: {
  workspace: WorkspaceDetailsPayload
}) {
  const { scrapi } = useMst()

  const currentValues: Inputs = {
    PROMPTER_INTEGRATION: hasOption(workspace, PROMPTER_INTEGRATION),
    SCRIPT_LIMITING: hasOption(workspace, SCRIPT_LIMITING),
    RUNDOWNS: hasOption(workspace, RUNDOWNS),
    PRIVATE_SCRIPTS: hasOption(workspace, PRIVATE_SCRIPTS),
  }

  const { register, watch, resetField } = useForm({
    defaultValues: {
      ...currentValues,
    },
  })

  const changeOption = React.useCallback(
    async (planOption: OrgOption, enabled: boolean) => {
      try {
        if (enabled) {
          await extractTsRestSuccess(
            scrapi.staff.workspacePlanOptionEnable({
              params: {
                code: planOption,
                id: workspace.id,
              },
              body: {},
            }),
            200,
          )
        } else {
          await extractTsRestSuccess(
            scrapi.staff.workspacePlanOptionDisable({
              params: {
                code: planOption,
                id: workspace.id,
              },
              body: {},
            }),
            200,
          )
        }
        resetField(planOption, { defaultValue: enabled })
      } catch (e) {
        ddLog.error('error on calling API', undefined, e)
        await showAlert({
          title: 'Error',
          children: 'Check the developer console for more information',
        })
      }
    },
    [scrapi, workspace.id, resetField],
  )

  React.useEffect(() => {
    const subscription = watch(async (data, { name, type }) => {
      if (name && type === 'change') {
        const enable = !!data[name]
        let confirmed = true
        // only ask for confirmation when disabling
        if (!enable) {
          const message = `Are you sure you want to disable ${name} for this workspace? ${type}`
          confirmed = window.confirm(message)
        }
        if (confirmed) {
          await changeOption(name, enable)
        } else {
          resetField(name)
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, changeOption, resetField])

  return (
    <form className="s-form s-form--inline">
      <div className="option-setting">
        <input
          id="PROMPTER_INTEGRATION"
          type="checkbox"
          {...register(PROMPTER_INTEGRATION)}
        />
        <label htmlFor="PROMPTER_INTEGRATION">Prompter Integration</label>
      </div>
      <div className="option-setting">
        <input
          id="PRIVATE_SCRIPTS"
          type="checkbox"
          {...register(PRIVATE_SCRIPTS)}
        />
        <label htmlFor="PRIVATE_SCRIPTS">Private Scripts</label>
      </div>
      <div className="option-setting">
        <input
          id="SCRIPT_LIMITING"
          type="checkbox"
          {...register(SCRIPT_LIMITING)}
        />
        <label htmlFor="SCRIPT_LIMITING">Limited-Access Scripts</label>
      </div>
      <div className="option-setting">
        <input id="RUNDOWNS" type="checkbox" {...register(RUNDOWNS)} />
        <label htmlFor="RUNDOWNS">Rundowns</label>
      </div>
    </form>
  )
}
