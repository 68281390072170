import React from 'react'

import { InputBase, InputBaseProps } from '@mantine/core'
import { IMaskInput as MaskInput } from 'react-imask'

import { FaIcon } from '@components/FaIcon'

export type PhoneNumberInputProps = InputBaseProps &
  Omit<React.ComponentPropsWithoutRef<typeof MaskInput>, keyof InputBaseProps>

const icon = <FaIcon icon="fa-mobile-screen" size="xl" />

export const PhoneNumberInput = (props: PhoneNumberInputProps) => {
  return (
    <InputBase
      type="tel"
      component={MaskInput}
      mask="+000000000000000"
      label="Phone number"
      placeholder="+1 (111) 111-1111"
      leftSection={icon}
      {...props}
    />
  )
}
