import { useState } from 'react'

import NiceModal from '@ebay/nice-modal-react'
import { TextInput } from '@mantine/core'

import { ModalShell, useModalControls } from '@components/Modals'
import { FormError } from '@components/Onboarding/FormErrors'
import { PasswordInput } from '@components/PasswordInput'
import { useMst } from '@state'

export const VerifyPassword = () => {
  const controls = useModalControls()
  const mst = useMst()
  const [password, setPassword] = useState('')
  const { email } = mst.user

  const verify = async () => {
    controls.setLoading(true)
    const opts = { email, password }
    const result = await mst.authManager.stytch.authenticateWithPassword(opts)

    result.success
      ? controls.onClose()
      : controls.setErrorMessage(<FormError code={result.code} />)

    controls.setLoading(false)
  }

  return (
    <ModalShell
      title="Verify current password"
      opened={controls.opened}
      onClose={controls.onClose}
      size="sm"
      confirmLabel="Submit"
      onConfirm={verify}
      errorMessage={controls.errorMessage}
    >
      {/* purely for accessibility https://goo.gl/9p2vKq */}
      <TextInput value={email} autoComplete="username" hidden readOnly />
      <PasswordInput
        autoComplete="current-password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
    </ModalShell>
  )
}

export const VerifyPasswordModal = NiceModal.create(VerifyPassword)
export const showVerifyPasswordModal = () => NiceModal.show(VerifyPasswordModal)
