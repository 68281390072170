import { Select as SelectComponent } from '@mantine/core'

import styles from './select.module.scss'

declare module '@mantine/core' {
  export interface SelectProps {
    /**
     * Mark the target as edited with a light background
     */
    'data-dirty'?: boolean
  }
}

export const Select = SelectComponent.extend({
  defaultProps: {
    allowDeselect: false,
    withCheckIcon: false,
  },
  classNames: styles,
})
