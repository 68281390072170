import {
  StytchAPIError,
  StytchHeadlessClient,
  User as StytchUser,
} from '@stytch/vanilla-js/headless'

import { schemas, ZInfer } from '@showrunner/scrapi'

export type AuthErrorCode = ZInfer<typeof schemas.AuthErrorCode>

export type LoginResult =
  | {
      success: true
    }
  | {
      success: false
      code: AuthErrorCode
    }

export type StytchAuthenticateResponse =
  | {
      success: true
      sessionToken: string
    }
  | {
      success: false
      code: AuthErrorCode
    }

export type StytchSendOtpResponse =
  | {
      success: true
      methodId: string
    }
  | {
      success: false
      code: AuthErrorCode
    }

export type StytchDeletePhoneNumberResponse =
  | {
      success: true
    }
  | {
      success: false
      code: string
    }

// when we call some stytch sdk methods, we get back a raw string. Some
// we don't know how to handle, others we deal with explicitly
const stytchErrorMap: { [key: string]: AuthErrorCode } = {
  unauthorized_credentials: 'INVALID_CREDENTIALS',
  // in the UI we treat a missing email the same as an invalid password
  email_not_found: 'INVALID_CREDENTIALS',
  // this is returned when passwords get compromised
  reset_password: 'RESET_PASSWORD',
}

export interface IStytchClient {
  authenticateWithPassword: (args: {
    email: string
    password: string
  }) => Promise<StytchAuthenticateResponse>

  authenticateWithOtp: (
    otp: string,
    passCodeId: string,
  ) => Promise<StytchAuthenticateResponse>

  deletePhoneNumber: (
    phoneNumber: string,
  ) => Promise<StytchDeletePhoneNumberResponse>
  getUserSync: () => StytchUser | null

  sendOtp: (
    phoneNumber: string,
    method: 'sms' | 'whatsapp',
  ) => Promise<StytchSendOtpResponse>
}

export class DevStytchClient implements IStytchClient {
  authenticateWithPassword = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }): Promise<StytchAuthenticateResponse> => {
    if (password !== 'I am strong') {
      return {
        success: false,
        code: 'INVALID_CREDENTIALS',
      }
    }
    return Promise.resolve({
      success: true,
      sessionToken: email,
    })
  }

  authenticateWithOtp = async (
    otp: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    passCodeId: string,
  ): Promise<StytchAuthenticateResponse> => {
    if (otp !== '000000') {
      return {
        success: false,
        code: 'INVALID_CREDENTIALS',
      }
    }
    return Promise.resolve({
      success: true,
      sessionToken: otp,
    })
  }

  sendOtp = async (phoneNumber: string, method: 'sms' | 'whatsapp') => {
    return Promise.resolve({
      success: true,
      methodId: method + phoneNumber + '-fake',
    } as { success: true; methodId: string })
  }

  deletePhoneNumber = async () => {
    return Promise.resolve({ success: true } as { success: true })
  }
  getUserSync = () => null
}

// wrap the stytch client so we can return expected failures in a nicer way
export class StytchClient implements IStytchClient {
  readonly _rawClient: StytchHeadlessClient
  constructor(publicToken: string) {
    this._rawClient = new StytchHeadlessClient(publicToken)
  }

  authenticateWithPassword = async ({
    email,
    password,
  }: {
    email: string
    password: string
  }): Promise<StytchAuthenticateResponse> => {
    try {
      const stychResponse = await this._rawClient.passwords.authenticate({
        email,
        password,
        session_duration_minutes: 10,
      })
      return {
        success: true,
        sessionToken: stychResponse.session_token,
      }
    } catch (e) {
      if (e instanceof StytchAPIError) {
        const code = stytchErrorMap[e.error_type]
        if (code) {
          return { success: false, code }
        }
      }
      return {
        success: false,
        code: 'UNEXPECTED_ERROR',
      }
    }
  }

  // TODO: add more explicit response error codes to map
  authenticateWithOtp = async (
    otp: string,
    passCodeId: string,
  ): Promise<StytchAuthenticateResponse> => {
    try {
      const stychResponse = await this._rawClient.otps.authenticate(
        otp,
        passCodeId,
        { session_duration_minutes: 5 },
      )
      return {
        success: true,
        sessionToken: stychResponse.session_token,
      }
    } catch (e) {
      if (e instanceof StytchAPIError) {
        const code = stytchErrorMap[e.error_type]
        if (code) {
          return { success: false, code }
        }
      }
      return {
        success: false,
        code: 'UNEXPECTED_ERROR',
      }
    }
  }

  // TODO: add more explicit response error codes to map
  sendOtp = async (
    phoneNumber: string,
    method: 'sms' | 'whatsapp',
  ): Promise<StytchSendOtpResponse> => {
    try {
      const { method_id } = await this._rawClient.otps[method].send(
        phoneNumber,
        { expiration_minutes: 5 },
      )
      return {
        success: true,
        methodId: method_id,
      }
    } catch (e) {
      if (e instanceof StytchAPIError) {
        const code = stytchErrorMap[e.error_type]
        if (code) {
          return { success: false, code }
        }
      }
      return {
        success: false,
        code: 'UNEXPECTED_ERROR',
      }
    }
  }

  // TODO: add more explicit response error codes to map
  deletePhoneNumber = async (phoneId: string) => {
    try {
      await this._rawClient.user.deletePhoneNumber(phoneId)
      return { success: true } as { success: true }
    } catch (e) {
      if (e instanceof StytchAPIError) {
        const code = stytchErrorMap[e.error_type]
        if (code) {
          return { success: false, code } as { success: false; code: string }
        }
      }
      return {
        success: false,
        code: 'UNEXPECTED_ERROR',
      } as { success: false; code: string }
    }
  }

  getUserSync = () => this._rawClient.user.getSync()
}

export const getStytchClient = (token: string | null) => {
  if (!token) {
    return new DevStytchClient()
  }
  return new StytchClient(token)
}
