import {
  Button,
  Card,
  Group,
  Stack,
  Text,
  TextInput,
  Title,
  type TitleProps,
} from '@mantine/core'
import { observer } from 'mobx-react-lite'

import { FaIcon } from '@components/FaIcon'
import {
  showAlert,
  showDisablePhoneAuthModal,
  showEnablePhoneAuthModal,
  showVerifyPasswordModal,
} from '@components/Modals'
import { FormError } from '@components/Onboarding/FormErrors'
import { PasswordInput, useStrongPasswordForm } from '@components/PasswordInput'
import { Toast } from '@components/Toast'
import { useMst } from '@state'
import * as formUtil from '@util/formUtil'
import { parseServerError } from '@util/parseServerError'

type FormValues = {
  oldPassword: string
  newPassword: string
}

export const Security = observer(function Security() {
  const { apiClient, view, authManager, user } = useMst()

  const { form, passwordStrength } = useStrongPasswordForm<FormValues>({
    passwordField: 'newPassword',
    validateInputOnBlur: true,
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    validate: {
      oldPassword: formUtil.validateNotEmpty,
      newPassword: formUtil.validateNotEmpty,
    },
  })

  const handleSubmit = async ({ oldPassword, newPassword }: FormValues) => {
    try {
      await apiClient.changeStytchPassword({
        oldPassword,
        newPassword,
      })
      form.reset()
      showAlert({
        title: 'Success',
        children: 'Your password has been changed',
      })
    } catch (e) {
      const { code, message } = parseServerError(e)
      showAlert({
        title: 'Error',
        children: (
          <Toast
            type="error"
            dismissable={false}
            message={<FormError code={code ?? message} />}
          />
        ),
      })
    }
  }

  const { mfaEnabled, stytchUser } = authManager.stytch

  return (
    <Stack miw={500} pl={30} pr={30} gap={5}>
      <Title fz={30}>Security</Title>
      <Subtitle mb={10}>Change password</Subtitle>
      <Card mb="xl">
        <form style={{ width: 300 }} onSubmit={form.onSubmit(handleSubmit)}>
          <Stack gap="md">
            <TextInput
              readOnly
              hidden
              display="none"
              value={user.email}
              aria-label="Email"
              autoComplete="username"
            />
            <PasswordInput
              label="Current password"
              autoComplete="current-password"
              {...form.getInputProps('oldPassword')}
            />
            <PasswordInput
              label="New password"
              autoComplete="new-password"
              strengthInfo={passwordStrength}
              {...form.getInputProps('newPassword')}
            />
            <Group>
              <Button disabled={!form.isValid()} type="submit">
                Save changes
              </Button>
            </Group>
          </Stack>
        </form>
      </Card>
      {view.mfaRequired && (
        <>
          <Subtitle>Two-factor authentication</Subtitle>
          <Group wrap="nowrap">
            <Text component="p" mb="sm">
              Add an additional layer of security for your account by requiring
              an additional check when signing in.
            </Text>
          </Group>
          <Card>
            <Group align="center" justify="space-between">
              <Group>
                <FaIcon icon="fa-message-sms" size="xl" />
                <Stack gap={5}>
                  <Text fw="bold">SMS / text message</Text>
                  <Text>
                    {mfaEnabled
                      ? 'You will receive a one-time code through your phone'
                      : 'Receive one-time codes through your cell phone'}
                  </Text>
                </Stack>
              </Group>
              {stytchUser ? (
                <Group>
                  <Text c={mfaEnabled ? 'green' : 'gray'}>
                    {mfaEnabled ? 'Enabled' : 'Not enabled'}
                  </Text>
                  <Button
                    variant="subtle"
                    ms="auto"
                    onClick={
                      mfaEnabled
                        ? showDisablePhoneAuthModal
                        : showEnablePhoneAuthModal
                    }
                  >
                    {mfaEnabled ? 'Disable' : 'Enable'}
                  </Button>
                </Group>
              ) : (
                <Button
                  variant="subtle"
                  ms="auto"
                  onClick={showVerifyPasswordModal}
                >
                  Manage
                </Button>
              )}
            </Group>
          </Card>
        </>
      )}
    </Stack>
  )
})

function Subtitle(props: TitleProps) {
  return <Title order={2} size="lg" ff="inherit" {...props} />
}
