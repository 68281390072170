import { useState } from 'react'

import NiceModal from '@ebay/nice-modal-react'
import {
  Button,
  Group,
  LoadingOverlay,
  Modal,
  PinInput,
  Radio,
  Space,
  Stack,
  Text,
} from '@mantine/core'
import { useForm } from '@mantine/form'

import { FaIcon } from '@components/FaIcon'
import { useModalControls } from '@components/Modals'
import { FormError } from '@components/Onboarding/FormErrors'
import { ThrottledBailoutLink } from '@components/Onboarding/OnboardingPage/BailoutLink'
import { Toast } from '@components/Toast'
import { useMst } from '@state'
import { formUtil } from '@util'

type FormValues = { otp: string }
type OtpDeliveryOption = 'sms' | 'whatsapp'

export const DisablePhoneAuth = () => {
  const controls = useModalControls()
  const mst = useMst()
  const [oneTimePasscodeId, setOneTimePasscodeId] = useState('')
  const [sendVia, setSendVia] = useState<OtpDeliveryOption>('sms')

  const phoneSubmitted = !!oneTimePasscodeId

  const form = useForm<FormValues>({
    initialValues: {
      otp: '',
    },
    validate: {
      otp: (value) => {
        if (oneTimePasscodeId) return formUtil.validateOtpCode(value)
      },
    },
  })

  const resendOtp = async () => {
    form.setFieldValue('otp', '')
    controls.setErrorMessage(null)
    controls.setLoading(true)
    await requestOtp()
    controls.setLoading(false)
  }

  const { verifiedPhone } = mst.authManager.stytch
  if (!verifiedPhone) return null

  const requestOtp = async () => {
    const result = await verifiedPhone.sendOneTimePasscode(sendVia)

    if (result.success) {
      setOneTimePasscodeId(result.methodId)
    } else {
      controls.setErrorMessage(<FormError code={result.code} />)
    }
  }

  const reset = () => {
    form.reset()
    controls.setErrorMessage(null)
    setOneTimePasscodeId('')
  }

  const handleClose = () => {
    reset()
    controls.onClose()
  }

  const handleSubmit = async ({ otp }: FormValues) => {
    controls.setLoading(true)

    if (phoneSubmitted) {
      const result = await mst.authManager.stytch.client.authenticateWithOtp(
        otp,
        oneTimePasscodeId,
      )

      if (result.success) {
        const deletion = await verifiedPhone.delete()
        if (!deletion.success) {
          controls.setErrorMessage(<FormError code={deletion.code} />)
        }
        controls.setLoading(false)
        handleClose()
      } else {
        controls.setErrorMessage(<FormError code={result.code} />)
      }
    } else {
      await requestOtp()
    }

    controls.setLoading(false)
  }

  return (
    <Modal
      title="SMS authentication"
      opened={controls.opened}
      onClose={controls.onClose}
      size="sm"
    >
      <LoadingOverlay visible={controls.loading} />
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          {controls.errorMessage && (
            <Toast
              type="error"
              dismissable={false}
              message={controls.errorMessage}
            />
          )}
          {phoneSubmitted ? (
            <>
              <Text>
                Please provide the 6-digit verification code that was just sent
                to{' '}
                <Text span fw="bold">
                  {verifiedPhone.obfuscatedNumber}
                </Text>
                .
              </Text>
              <Group justify="center">
                <Text fw="bold">Verification Code</Text>
                <PinInput
                  oneTimeCode
                  aria-label="One time code"
                  length={6}
                  {...form.getInputProps('otp')}
                />
              </Group>
            </>
          ) : (
            <>
              <Text>
                To disable SMS authentication, please verify access to the phone
                number you&apos;ve provided.
              </Text>
              <Text>
                Continue to receive a one-time verification code to{' '}
                <Text span fw="bold">
                  {verifiedPhone.obfuscatedNumber}
                </Text>
              </Text>
              <Radio.Group
                value={sendVia}
                onChange={(val) => setSendVia(val as OtpDeliveryOption)}
              >
                <Group>
                  <Radio label="SMS / text message" value="sms" />
                  <Radio
                    label={
                      <Group gap={3}>
                        <FaIcon icon="fa-brands fa-whatsapp" />
                        <Text>WhatsApp</Text>
                      </Group>
                    }
                    value="whatsapp"
                  />
                </Group>
              </Radio.Group>
            </>
          )}
        </Stack>
        <Space h={40} />
        <Stack>
          <Group gap="xs" justify="center">
            <Button variant="filled" type="submit">
              {phoneSubmitted ? 'Submit' : 'Send'}
            </Button>
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
          </Group>
        </Stack>
        {phoneSubmitted && (
          <>
            <Space h={20} />
            <ThrottledBailoutLink
              message="Didn't receive a code?"
              linkText="Resend"
              successText="Sent"
              onClick={resendOtp}
            />
          </>
        )}
      </form>
    </Modal>
  )
}

export const DisablePhoneAuthModal = NiceModal.create(DisablePhoneAuth)
export const showDisablePhoneAuthModal = () =>
  NiceModal.show(DisablePhoneAuthModal)
