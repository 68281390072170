import React from 'react'

import { Card, Stack } from '@mantine/core'

import { Toast } from '@components/Toast'

import styles from './OnboardingForm.module.scss'

type OnboardingFormProps = React.ComponentPropsWithoutRef<'form'> & {
  errorMessage?: string | React.ReactNode
  children: React.ReactNode
}

export const OnboardingForm = ({
  children,
  errorMessage,
  ...props
}: OnboardingFormProps) => {
  return (
    <Card className={styles.onboardingForm}>
      <form {...props}>
        <Stack>
          {errorMessage && (
            <Toast
              message={errorMessage}
              icon="fas fa-circle-exclamation"
              type="error"
              dismissable={false}
            />
          )}
          {children}
        </Stack>
      </form>
    </Card>
  )
}
