import { Divider, Stack } from '@mantine/core'

import { Breadcrumbs } from '../Breadcrumbs'

import { CreateRundownSchema } from './CreateRundownSchema'
import { RundownSchemaList } from './RundownSchemaList'

export const Rundowns = () => (
  <Stack>
    <Breadcrumbs crumbs={[{ text: 'Rundown schemas' }]} />
    <RundownSchemaList />
    <Divider />
    <CreateRundownSchema />
  </Stack>
)
