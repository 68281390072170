import React from 'react'

import { Button, Group, Stack, Text } from '@mantine/core'
import { useForm } from '@mantine/form'
import { Redirect, Route, Router, useRouter } from 'wouter'
import { navigate } from 'wouter/use-location'

import { OnboardingPage } from '@components/Onboarding/OnboardingPage'
import {
  BailoutLink,
  ThrottledBailoutLink,
} from '@components/Onboarding/OnboardingPage/BailoutLink'
import { PhoneNumberInput } from '@components/PhoneNumberInput'
import { Pinput } from '@components/Pinput'
import { RouteComponent } from '@routes/util'

/* eslint-disable no-console */
const FakeStytch = {
  sendCode(phoneNumber: string) {
    console.log(`(not) sending a code to ${phoneNumber}`)
    setTimeout(() => alert('Your code is:\n1 2 3 4 5 6'), 1000)
  },
  verifyCode(_code: string) {
    console.log(`(not) verifying code ${'*'.repeat(_code.length)}`)
  },
}
/* eslint-enable no-console */

export const SecondFactorRoute: RouteComponent = () => {
  const router = useRouter()
  const [isVerifying, setIsVerifying] = React.useState(false)

  const form = useForm({
    initialValues: {
      phoneNumber: '',
      verificationCode: '',
    },
  })

  function handlePhoneSubmit() {
    navigate('/second-factor/sms/verify')
    FakeStytch.sendCode(form.values.phoneNumber)
  }

  function handleVerificationSubmit() {
    FakeStytch.verifyCode(form.values.verificationCode)
    setIsVerifying(true)
  }

  return (
    <Router base="/second-factor" parent={router}>
      <Route path="/sms/new">
        <OnboardingPage title="Keep your account secure">
          <Stack gap="lg">
            <Stack gap="sm">
              <Text c="white" ta="center">
                Would you like to enable multi-factor authentication (MFA) for
                added protection?
              </Text>
              <Text c="white" ta="center">
                Enter your phone number to receive a one-time verification code
                when signing in.
              </Text>
            </Stack>
            <OnboardingPage.Form onSubmit={form.onSubmit(handlePhoneSubmit)}>
              <PhoneNumberInput
                autoFocus
                size="md"
                key={form.key('phoneNumber')}
                {...form.getInputProps('phoneNumber')}
              />
              <Group justify="center" gap="sm">
                <OnboardingPage.SubmitButton label="Text me a code" />
                <Button variant="outline" disabled>
                  No thanks
                </Button>
              </Group>
            </OnboardingPage.Form>
            <OnboardingPage.Links types={['support']} />
          </Stack>
        </OnboardingPage>
      </Route>

      <Route path="/sms/verify">
        <OnboardingPage title="Keep your account secure">
          <Stack gap="lg">
            <Stack gap="sm">
              <Text c="white" ta="center">
                Enter the 6-digit code we sent to
              </Text>
              <Text c="white" ta="center">
                {form.values.phoneNumber}
              </Text>
            </Stack>
            <OnboardingPage.Form>
              <Pinput
                autoFocus
                disabled={isVerifying}
                onComplete={handleVerificationSubmit}
                {...form.getInputProps('verificationCode')}
              />

              <ThrottledBailoutLink
                message="Didn't receive a verification code?"
                linkText="Resend"
                successText="Sent"
                onClick={() => FakeStytch.sendCode(form.values.phoneNumber)}
              />
              <BailoutLink
                message="Wrong phone number?"
                linkText="Start over"
                onClick={() => navigate('/second-factor/sms/new')}
              />
            </OnboardingPage.Form>
            <OnboardingPage.Links types={['support']} />
          </Stack>
        </OnboardingPage>
      </Route>
      <Redirect to="/sms/new" />
    </Router>
  )
}
