import { Code, Space, Text, Title } from '@mantine/core'
import { useQueries } from '@tanstack/react-query'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'

import { Loader } from '@components/Loader'
import { useStaffFormats } from '@hooks'
import { useMst } from '@state'
import { ROUTE_PATTERNS } from '@util'
import { extractTsRestSuccess } from '@util/extractTsRest'
import { BetaFlag, WorkspaceDetailsPayload } from '@util/ScriptoApiClient/types'

import { Breadcrumbs } from '../Breadcrumbs'
import { rundownSchemaQuery, staleTime } from '../helpers'

import { AccountStatusForm } from './AccountStatusForm'
import { BetaFlagsForm } from './BetaFlagsForm'
import { DefaultFormatsForm } from './DefaultFormatsForm'
import { MembersForm } from './MembersForm'
import { PlanOptionsForm } from './PlanOptionsForm'
import { ReadRateForm } from './ReadRateForm'
import { ReindexDocsForm } from './ReindexDocsForm'
import { RundownSchemaForm } from './RundownSchemaForm'
import { TierForm } from './TierForm'
import { StaffFormatSummary } from './util'

import './OrgDetail.scss'

const OrgDetailInternal = ({
  workspace,
  formats,
  betaFlags,
  schemaNames,
}: {
  workspace: WorkspaceDetailsPayload
  formats: StaffFormatSummary[]
  betaFlags: BetaFlag[]
  schemaNames: string[]
}) => (
  <div className="org-detail-staff-page">
    <Breadcrumbs
      crumbs={[
        { text: 'Workspaces', url: ROUTE_PATTERNS.staffWorkspaces },
        { text: workspace.name },
      ]}
    />

    <hr className="s-hr" />

    <Text>
      Created:&nbsp;
      <Code>{format(workspace.createdAt, "M/d/yyyy 'at' h:mmaaa")}</Code>
    </Text>

    <hr className="s-hr" />

    <h3>Account Status</h3>

    <Text>only used for internal KPIs/analytics</Text>
    <Space h={20} />
    <AccountStatusForm org={workspace} />
    <hr className="s-hr" />
    <h3>Tier</h3>
    <Text>
      controls what workspace features are available to the client and how many
      users they can invite
    </Text>
    <Space h={10} />
    <TierForm org={workspace} />
    <hr className="s-hr" />
    <h3>Default Script Formats</h3>
    <DefaultFormatsForm org={workspace} formats={formats} />
    <hr className="s-hr" />
    <h3>Plan Options</h3>
    <h4>
      ☠️ Turning off plan options is very destructive. Don&apos;t uncheck any
      options unless you know what you&apos;re doing. ☠️
    </h4>
    <PlanOptionsForm workspace={workspace} />
    <hr className="s-hr" />
    <h3>Change Read Rate</h3>
    <ReadRateForm org={workspace} />
    <hr className="s-hr" />
    <h3>Reindex Documents</h3>
    <ReindexDocsForm orgId={workspace.id} />
    <hr className="s-hr" />
    <h3>Members</h3>
    <MembersForm org={workspace} />
    <hr className="s-hr" />
    <h3>Feature Flags</h3>
    <h4>
      ☠️ Don&apos;t uncheck any flags unless you know what you&apos;re doing. ☠️
    </h4>
    <BetaFlagsForm org={workspace} betaFlags={betaFlags} />
    <hr className="s-hr" />
    <h3>Rundown Schema</h3>
    <RundownSchemaForm org={workspace} schemaNames={schemaNames} />
  </div>
)

export const OrgDetail = observer(function OrgDetail({
  params,
}: {
  params: { uuid: string }
}) {
  const { apiClient, scrapi } = useMst()
  const workspaceId = params.uuid

  const workspaceDetailsQuery = async () => {
    const { body } = await extractTsRestSuccess(
      scrapi.staff.workspaceDetails({ params: { id: workspaceId } }),
      200,
    )

    return body
  }

  const { formatsQuery } = useStaffFormats()

  const betaFlagQuery = async () => {
    const res = await apiClient.getBetaFlags()
    return res.features
  }

  const results = useQueries({
    queries: [
      {
        queryKey: ['staff_org_info', workspaceId],
        queryFn: workspaceDetailsQuery,
        // we fetch workspace details each time to avoid showing stale info
        gcTime: 0,
        staleTime: Infinity,
      },
      {
        queryKey: ['staff_flag_query'],
        queryFn: betaFlagQuery,
        staleTime,
      },
      rundownSchemaQuery(apiClient),
    ],
  })

  const isLoading = results.some((r) => r.isLoading) || formatsQuery.isLoading
  const isError = results.some((r) => r.isError) || formatsQuery.isError

  if (isLoading) return <Loader fullScreen={true} />

  const workspace = results[0].data
  const formats = formatsQuery.data
  const betaFlags = results[1].data
  const schemaNames = results[2].data
    ?.map((s) => s.name)
    .sort((a, b) => a.localeCompare(b))

  if (isError || !workspace || !formats || !betaFlags) {
    return <Title>Unexpected error</Title>
  }

  return (
    <OrgDetailInternal
      workspace={workspace}
      formats={formats}
      betaFlags={betaFlags}
      schemaNames={schemaNames ?? []}
    />
  )
})
