import React from 'react'

import {
  Button,
  Divider,
  FileButton,
  Group,
  Space,
  Stack,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core'
import { isNotEmpty, useForm } from '@mantine/form'

import { schemas } from '@showrunner/scrapi'

import { CreateCard } from '@components/Dashboard/CreateCard'
import { showAlert, showError } from '@components/Modals'
import { OutsideAnchor } from '@components/OutsideAnchor'
import { useStaffFormats } from '@hooks'
import { useMst } from '@state'
import { safeParseJSON } from '@util'
import { extractTsRestSuccess } from '@util/extractTsRest'
import { ScriptFormatConfiguration } from '@util/formats'

import { Breadcrumbs } from '../Breadcrumbs'

import { FormatsList } from './FormatsList'

type Inputs = {
  id: string
  name: string
  description: string
  icon: string
  definitionJson: ScriptFormatConfiguration | null
  notes: string
}

const ID_REGEX = /^[a-zA-Z0-9_-]*$/

const extractJsonFromFile = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      return resolve(safeParseJSON(reader.result))
    }
    reader.onerror = () => reject(new Error('Unknown Error'))
    reader.readAsText(file as Blob)
  })

export const FormatEditor = () => {
  const mst = useMst()

  const [fileName, setFileName] = React.useState('')

  const [definitionError, setDefinitionError] = React.useState('')
  const { refresh } = useStaffFormats()

  const form = useForm<Inputs>({
    validateInputOnBlur: true,
    validate: {
      id: (value) => {
        if (!value) {
          return 'Required'
        }
        if (!ID_REGEX.test(value)) {
          return `Use only letters, numbers, dash and underscore in IDs`
        }
      },
      name: isNotEmpty('Required'),
      description: isNotEmpty('Required'),
      icon: (value) => {
        if (!value?.startsWith('fa-')) {
          return 'Use a font-awesome icon that starts with "fa-"'
        }
      },
      definitionJson: isNotEmpty('Required'),
    },
    initialValues: {
      id: '',
      name: '',
      description: '',
      icon: '',
      definitionJson: null,
      notes: '',
    },
  })

  const saveNewFormat = async () => {
    const { id, name, description, icon, definitionJson, notes } = form.values
    if (!definitionJson) {
      return
    }

    try {
      await extractTsRestSuccess(
        mst.scrapi.staff.createFormat({
          body: {
            definition: definitionJson,
            description,
            icon,
            id,
            name,
            notes,
            active: true,
          },
        }),
        200,
      )

      showAlert({
        title: 'New format created',
        children: `You can now assign ${id} as a studioFormat or screenplayFormat to individual shows`,
      })
      refresh()
      form.reset()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      showError({
        message:
          'An unexpected error occurred. See browser console for details.',
      })
    }
  }

  const handleUploadFile = async (value: File | null) => {
    if (value) {
      form.setFieldValue('definitionJson', null)
      setDefinitionError('')
      setFileName(value.name)
      const json = await extractJsonFromFile(value)
      if (!json) {
        setDefinitionError('Invalid json file')
        return
      }
      const parsed =
        schemas.scriptFormats.ScriptFormatDefinition.safeParse(json)

      if (parsed.success) {
        if (!parsed.data.paginationType) {
          setDefinitionError('That format does not specify a pagination type')
        } else if (parsed.data.paginationType === 'structural') {
          setDefinitionError('That format uses structural pagination')
        } else {
          form.setFieldValue('definitionJson', parsed.data)
        }
      }
    }
  }

  return (
    <form style={{ height: '100%' }}>
      <Stack align="center" gap="xl">
        <Breadcrumbs crumbs={[{ text: 'Formats' }]} />
        <Group align="flex-start" gap={100}>
          <Stack align="flex-start">
            <Text fw="bold" size="md">
              DATA
            </Text>
            <TextInput
              w={300}
              label="id"
              description="A unique id (not user facing)"
              placeholder="new-custom-screenplay-2024-02-01"
              {...form.getInputProps('id')}
            />
            <TextInput
              w={300}
              label="User facing format name"
              placeholder="Studio Script"
              {...form.getInputProps('name')}
            />
            <Textarea
              w={300}
              size="md"
              label=" User-facing Description"
              placeholder="For monologues, desk pieces, and scripts that will be teleprompted"
              {...form.getInputProps('description')}
            />
            <TextInput
              w={300}
              label="User-facing fontawesome Icon"
              placeholder="fa-unicorn fa-regular"
              {...form.getInputProps('icon')}
            />
            <OutsideAnchor
              style={{ marginTop: -10 }}
              href="https://fontawesome.com/search"
            >
              Find an icon
            </OutsideAnchor>
            <Textarea
              w={300}
              size="md"
              label="Notes"
              placeholder="not user facing"
              {...form.getInputProps('notes')}
            />
            <FileButton onChange={handleUploadFile} accept="application/json">
              {(props) => (
                <Button variant="outline" {...props}>
                  Upload schema definition
                </Button>
              )}
            </FileButton>
            <>
              {fileName && <Text>{fileName}</Text>}
              {definitionError && <Text c="red">{definitionError}</Text>}
            </>
          </Stack>
          <Stack gap="xl" align="center">
            <Text fw="bold" size="md">
              PREVIEW
            </Text>

            <div style={{ minWidth: 250 }}>
              <CreateCard
                name={form.values.name || '<name>'}
                description={form.values.description || '<description>'}
                icon={form.values.icon || 'fa-unicorn fa-regular'}
              />
            </div>
          </Stack>
        </Group>
        <Group>
          <Button
            onClick={saveNewFormat}
            disabled={!form.isValid}
            color={!form.isValid() ? 'gray' : 'violet'}
          >
            Create New Format
          </Button>
        </Group>
        <Divider />
        <FormatsList />
        <Space h={100} />
      </Stack>
    </form>
  )
}
