import { Button, Group, NumberInput, Select, Stack, Text } from '@mantine/core'

import { AlignmentType } from '@showrunner/codex'

import { ILoadedScript } from '@state'
import { capitalize } from '@util'
import {
  BlockOverrides,
  DefinedSingleBlockOverride,
  FormatBlockName,
  FormatOption,
  FormatOptionValue,
} from '@util/formats'

import { BulkChangeHandler } from './helpers'

const alignmentMap: Record<string, AlignmentType> = {
  Left: 'left',
  Right: 'right',
  Center: 'center',
}

export const LayoutInputs = (props: {
  script: ILoadedScript
  pendingOverrides: BlockOverrides
  selectedBlock: FormatBlockName
  blockTitle?: string
  onChange: BulkChangeHandler
}) => {
  const { script, pendingOverrides, selectedBlock, onChange } = props
  const { pageLayout } = script

  const getPendingValue = <T extends FormatOption>(
    key: T,
  ): FormatOptionValue<T> | undefined => {
    if (pendingOverrides && pendingOverrides[selectedBlock]) {
      const blockOverrides: DefinedSingleBlockOverride =
        pendingOverrides[selectedBlock]
      const pendingValue = blockOverrides[key]
      if (pendingValue !== undefined) {
        return pendingValue as FormatOptionValue<T>
      }
    }
  }

  const getCurrentState = <T extends FormatOption>(key: T) => {
    const pendingValue = getPendingValue(key)
    const currentValue = script.currentFormatValue(selectedBlock, key)
    const value = pendingValue ?? currentValue
    const originalValue = script.originalFormatValue(selectedBlock, key)
    const isOverridden = value !== originalValue
    return { value, isOverridden }
  }

  const currentAlignment = getCurrentState('alignment')
  const currentLineHeight = getCurrentState('lineHeight')
  const currentBlockTopMargin = getCurrentState('blockTopMargin')
  const currentMarginLeft = getCurrentState('marginLeft')
  const currentWidth = getCurrentState('width')

  const hasLayoutOverrides = [
    currentAlignment,
    currentLineHeight,
    currentBlockTopMargin,
    currentMarginLeft,
    currentWidth,
  ].some((value) => value.isOverridden)

  const resetLayoutValues = () => {
    onChange(selectedBlock, {
      alignment: script.originalFormatValue(selectedBlock, 'alignment'),
      lineHeight: script.originalFormatValue(selectedBlock, 'lineHeight'),
      blockTopMargin: script.originalFormatValue(
        selectedBlock,
        'blockTopMargin',
      ),
      marginLeft: script.originalFormatValue(selectedBlock, 'marginLeft'),
      width: script.originalFormatValue(selectedBlock, 'width'),
    })
  }

  const marginConfig = {
    marginLeft: currentMarginLeft.value,
    width: currentWidth.value,
  }

  const displayedMargins = pageLayout.marginConfigToDisplayUnits(marginConfig)
  const invalidMargins = pageLayout.isTooNarrow(marginConfig.width)

  const { marginLeftMin, marginLeftMax, marginRightMin, marginRightMax } =
    pageLayout.limitsInDisplayUnits

  // This is a little tricky. We don't measure the page size in terms of left
  // and right margins, we measure it using the left margin and a width.
  // Therefore, setting a new left margin also increases the width. To determine
  // if the right margin has changed, we can't therefore compare widths — we
  // need to compare the computed right margin value against what the right
  // margin value would be giving the script format's value:
  const defaultMargins = pageLayout.marginConfigToDisplayUnits({
    marginLeft: script.originalFormatValue(selectedBlock, 'marginLeft'),
    width: script.originalFormatValue(selectedBlock, 'width'),
  })
  const isRightMarginOverridden =
    displayedMargins.right !== defaultMargins.right

  const suffix = pageLayout.data.unit === 'cm' ? 'cm' : '"'

  return (
    <Stack align="start" gap={20}>
      <Stack gap={2}>
        <Text fw="bold">Text alignment</Text>
        <Select
          size="md"
          data={['Left', 'Center', 'Right']}
          value={capitalize(currentAlignment.value)}
          onChange={(a) =>
            onChange(selectedBlock, { alignment: alignmentMap[a ?? 'Left'] })
          }
          inputSize="6"
          data-dirty={currentAlignment.isOverridden}
        />
      </Stack>
      <Group gap={20}>
        <Stack gap={2}>
          <Text fw="bold">Line spacing</Text>
          <Select
            size="md"
            inputSize="6"
            data={['1', '1.5', '2']}
            value={String(currentLineHeight.value)}
            onChange={(a) => {
              const lineHeight = Number(a) ?? currentLineHeight.value
              onChange(selectedBlock, { lineHeight })
            }}
            data-dirty={currentLineHeight.isOverridden}
          />
        </Stack>
        <NumberInput
          inputSize="8"
          label="Space above"
          allowDecimal={false}
          max={30}
          min={0}
          value={currentBlockTopMargin.value}
          onChange={(val) =>
            onChange(selectedBlock, { blockTopMargin: Number(val) })
          }
          data-dirty={currentBlockTopMargin.isOverridden}
        />
      </Group>
      <Stack gap={5}>
        <Group gap={20}>
          <NumberInput
            inputSize="8"
            label="Left margin"
            decimalScale={1}
            step={0.5}
            error={invalidMargins}
            min={marginLeftMin}
            max={marginLeftMax}
            allowNegative={false}
            clampBehavior="strict"
            suffix={suffix}
            value={displayedMargins.left}
            onChange={(rawVal) => {
              const val = Number(rawVal)
              const marginConfig = pageLayout.marginDisplayToConfig({
                left: val,
                right: displayedMargins.right,
              })
              onChange(selectedBlock, marginConfig)
            }}
            data-dirty={currentMarginLeft.isOverridden}
          />
          <NumberInput
            inputSize="8"
            label="Right margin"
            decimalScale={1}
            step={0.5}
            error={invalidMargins}
            min={marginRightMin}
            max={marginRightMax}
            allowNegative={false}
            clampBehavior="strict"
            suffix={suffix}
            value={displayedMargins.right}
            onChange={(value) => {
              const { width } = pageLayout.marginDisplayToConfig({
                left: displayedMargins.left,
                right: Number(value),
              })
              onChange(selectedBlock, { width })
            }}
            data-dirty={isRightMarginOverridden}
          />
        </Group>
        {invalidMargins && (
          <Text span c="red" size="sm">
            Blocks must be at least 1&rdquo; wide
          </Text>
        )}
      </Stack>

      <Button
        variant="outline"
        disabled={!hasLayoutOverrides}
        onClick={resetLayoutValues}
      >
        Reset {props.blockTitle ?? "this block's"} layout
      </Button>
    </Stack>
  )
}
