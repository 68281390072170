import { useQuery, useQueryClient } from '@tanstack/react-query'

import { schemas, ZInfer } from '@showrunner/scrapi'

import { useMst } from '@state'
import { ScriptoApiClient } from '@util'

// 15 minutes seems often enough to refetch
export const staleTime = 1000 * 60 * 15

export const SCHEMA_QUERY_KEY = 'staff_schema_query'
const BETA_FLAG_QUERY_KEY = 'fetch_beta_flags'

export const rundownSchemaQuery = (apiClient: ScriptoApiClient) => {
  return {
    queryKey: [SCHEMA_QUERY_KEY],
    queryFn: async () => await apiClient.fetchRundownSchemas(),
    staleTime: 1000 * 60 * 15,
  }
}

export const useBetaFlags = (apiClient: ScriptoApiClient) => {
  const { doDebug } = useMst()
  const queryClient = useQueryClient()

  const { data, isFetching, isError } = useQuery({
    queryFn: async () => {
      await doDebug()
      return apiClient.getBetaFlags()
    },
    queryKey: [BETA_FLAG_QUERY_KEY],
    staleTime: staleTime,
  })

  const invalidateQuery = async () =>
    await queryClient.invalidateQueries({ queryKey: [BETA_FLAG_QUERY_KEY] })

  return {
    betaFlags: data?.features,
    isError,
    isFetching,
    invalidateQuery,
  }
}

export const displayRole = (isOwner: boolean, isAdmin: boolean) =>
  isOwner ? 'Owner' : isAdmin ? 'Admin' : 'Contributor'

export type StaffScriptFormatSummary = ZInfer<
  typeof schemas.scriptFormats.StaffScriptFormatSummary
>
