import React from 'react'

import { useSearch as useBrowserSearch } from 'wouter/use-location'

import { BeforeRouteChangeHandler, RouteChangeHandler } from './util'
import { WallabyRouterContext } from './util/WallabyRouter'

const useWallabyRouter = () => {
  const { registerUnloadHandler, registerBeforeUnloadHandler } =
    React.useContext(WallabyRouterContext)
  return {
    registerUnloadHandler,
    registerBeforeUnloadHandler,
  }
}
// This hook registers a handler to run BEFORE the route changes. This does not
// have any influence on the route changing, it just triggers an effect
// before the route changes (e.g. tear down some global state when leaving
// the editor)
export const useUnloadRouteEffect = (handler: RouteChangeHandler) => {
  const { registerUnloadHandler } = useWallabyRouter()

  React.useEffect(() => {
    return registerUnloadHandler(handler)
  })
}

export const useBlockRouteChangeEffect = (
  handler: BeforeRouteChangeHandler,
) => {
  const { registerBeforeUnloadHandler } = useWallabyRouter()

  React.useEffect(() => {
    return registerBeforeUnloadHandler(handler)
  })
}

// TODO [wouter@>=3.4] update imports to use wouter's useSearchParams
export function useSearchParams() {
  const search = useSearch()

  const searchParams = React.useMemo(
    () => new URLSearchParams(search),
    [search],
  )

  return [searchParams]
}

// TODO [wouter@>=3.4] update imports to use wouter's useSeach
export function useSearch() {
  let search = useBrowserSearch()

  if (search[0] === '?') {
    search = search.slice(1)
  }

  try {
    return decodeURI(search)
  } catch (_error) {
    return search
  }
}
