import React from 'react'

import { useModal } from '@ebay/nice-modal-react'

// This hook is used by modal contents to get access to the outside state
// of the modal
export const useModalControls = () => {
  const { visible, hide, remove, resolve } = useModal()
  const [loading, setLoading] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState<React.ReactNode>('')

  const handleClose = () => {
    hide()
    setLoading(false)
    setDisabled(false)
    setErrorMessage('')
    // Note: this unmounts the modal from the DOM 500ms after opened is set to false.
    // We need the delay so that the animated exit transition can complete.
    setTimeout(remove, 500)
  }

  return {
    opened: visible,
    onClose: handleClose,
    loading,
    setLoading,
    disabled,
    setDisabled,
    errorMessage,
    setErrorMessage,
    resolve,
  }
}
