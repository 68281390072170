import { CloseButton as CloseButtonComponent } from '@mantine/core'

import { FaIcon } from '@components/FaIcon'

import styles from './close-button.module.css'

export const CloseButton = CloseButtonComponent.extend({
  classNames: styles,
  defaultProps: {
    icon: <FaIcon icon="fa-xmark" size="18" />,
  },
})
