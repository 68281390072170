import jsc from 'js-cookie'

import { BaseModel } from '@state/models/BaseModel'
import { reloadWindow } from '@util'
import { authToken } from '@util/authToken'
import { AuthErrorCode } from '@util/stytch'

import { StytchMrManager } from './Stytch'

type LoggedInStatus = {
  loggedIn: true
  userId: string
}

type LoggedOutStatus = {
  loggedIn: false
}

export type LoginResult =
  | {
      success: true
    }
  | {
      success: false
      code: AuthErrorCode
    }

type AuthStatus = LoggedInStatus | LoggedOutStatus

/*
  This model wraps the calls to login, logout and check auth status to handle
  all the variations of scrapi vs. api and making sure that the in-memory
  singleton auth token gets populated properly
*/
export const AuthManager = BaseModel.named('AuthManager')
  .props({
    stytch: StytchMrManager,
  })
  .actions((self) => ({
    _onLoggedIn(token: string) {
      self.environment.localPersistence.markLoggedIn(true)
      authToken.set(token)
    },

    async getAuthStatus(): Promise<AuthStatus> {
      const response = await self.scrapi.auth.status()
      if (response.status === 200) {
        if (response.body.loggedIn) {
          const { socketToken, id } = response.body
          this._onLoggedIn(socketToken)
          return { loggedIn: true, userId: id }
        }
        // only clear the token on an explicit failure, we might have gotten
        // another kind of failure
        authToken.clear()
      }
      return { loggedIn: false }
    },

    async loginViaStytch(args: {
      email: string
      password: string
    }): Promise<LoginResult> {
      const stytchResult = await self.stytch.authenticateWithPassword(args)
      if (!stytchResult.success) {
        return stytchResult
      }

      const scrapiResult = await self.scrapi.auth.createSession({
        body: { type: 'stytchSession', email: args.email },
      })

      if (scrapiResult.status === 200) {
        this._onLoggedIn(scrapiResult.body.socketToken)
        return { success: true }
      } else if (scrapiResult.status === 420) {
        return {
          success: false,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          code: (scrapiResult as any).body.code,
        }
      } else {
        return {
          success: false,
          code: 'UNEXPECTED_ERROR',
        }
      }
    },

    async loginViaNido(args: {
      email: string
      password: string
    }): Promise<LoginResult> {
      const response = await self.scrapi.auth.login({ body: args })
      if (response.status === 200) {
        this._onLoggedIn(response.body.socketToken)
        return { success: true }
      } else if (response.status === 420) {
        return {
          success: false,
          code: response.body.code,
        }
      } else {
        return {
          success: false,
          code: 'UNEXPECTED_ERROR',
        }
      }
    },

    async logout() {
      const response = await self.scrapi.auth.logout({ body: {} })
      // make scrapi throw if this fails since we haven't successfully
      // logged out
      if (response.status !== 200) {
        throw new Error('Unknown server error', { cause: 200 })
      }

      authToken.clear()
      self.environment.localPersistence.markLoggedIn(false)

      // ensure we clean up any short-lived raw valid stytch sessions that we encounter
      jsc.remove('stytch_session')
      jsc.remove('stytch_session_jwt')

      self.trackEvent('LOGOUT')
      await reloadWindow('/login')
    },
  }))
