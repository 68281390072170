// TRANSIENT_FLAGS
// for experimental nido endpoints
export const NIDO_ALPHA = 'nido-alpha'
// for nido endpoints nearing readiness for general availability
export const NIDO_BETA = 'nido-beta'
// Short-term kill switch if connecting to nido for sockets gives us
// any grief
export const LEGACY_SOCKETS = 'nido-sockets'

// PERMANENT FLAGS
