import { DatadogClient } from '@util/datadog'

import {
  KNOWN_SERVERS,
  KnownServer,
  NamedServerConfig,
  ServerConfig,
} from './types'

const ddLog = DatadogClient.getInstance()

const PRODUCTION_MIXPANEL_TOKEN = '5f19e7b02416d724c5f7713034ac1f8f'
const STAGING_MIXPANEL_TOKEN = '254c5914299dd6c26d474fb3ea8aa02c'
const SLACK_CLIENT_ID = '2166313684.1525898859411'
const LOCAL_SLACK_CLIENT_ID = '2166313684.1988920550002'
type KnownHostedServer = Exclude<KnownServer, 'local'>

function isKnownServer(value: string): value is KnownHostedServer {
  return KNOWN_SERVERS.includes(value as KnownServer)
}
// NOTE: no intercom value is set. It's either on/off and handled
// in config.js during the build process
const LOCAL: ServerConfig = {
  API_URL: 'http://localhost:3004',
  NIDO_URL: 'http://localhost:3008',
  LOG_LEVEL: 'info',
  MIXPANEL_TOKEN: '',
  NODE_ENV: 'development',
  SLACK_CLIENT_ID: LOCAL_SLACK_CLIENT_ID,
  HTTP_TIMEOUT_MS: 30_000,
  // if you are using real stytch with a localhost server, you need to set up
  // a stytch project and populate the key here
  STYTCH_PUBLIC_TOKEN: null,
}

const HOSTED_SERVER_CONFIGS: Record<
  KnownHostedServer,
  Omit<ServerConfig, 'NODE_ENV'>
> = {
  production: {
    API_URL: 'https://api.production.scripto.live',
    NIDO_URL: 'https://nido.production.scripto.live',
    MIXPANEL_TOKEN: PRODUCTION_MIXPANEL_TOKEN,
    SLACK_CLIENT_ID,
    LOG_LEVEL: 'info',
    STYTCH_PUBLIC_TOKEN:
      'public-token-live-ef174cd0-ebec-4e90-a4d1-2a2638a1f524',
  },
  staging: {
    API_URL: 'https://api.staging.scripto.live',
    NIDO_URL: 'https://nido.staging.scripto.live',
    MIXPANEL_TOKEN: STAGING_MIXPANEL_TOKEN,
    SLACK_CLIENT_ID,
    LOG_LEVEL: 'info',
    STYTCH_PUBLIC_TOKEN:
      'public-token-test-af9e1710-ba0c-4fae-8149-d5e75df996e6',
  },
  skatepark: {
    API_URL: 'https://api.skatepark.scripto.live',
    NIDO_URL: 'https://nido.skatepark.scripto.live',
    MIXPANEL_TOKEN: STAGING_MIXPANEL_TOKEN,
    SLACK_CLIENT_ID,
    LOG_LEVEL: 'info',
    STYTCH_PUBLIC_TOKEN:
      'public-token-test-cc562c90-d0eb-4c1b-a1d6-4addbad29e94',
  },
  flanders: {
    API_URL: 'https://api.flanders.scripto.live',
    NIDO_URL: 'https://nido.flanders.scripto.live',
    MIXPANEL_TOKEN: STAGING_MIXPANEL_TOKEN,
    SLACK_CLIENT_ID,
    LOG_LEVEL: 'info',
    STYTCH_PUBLIC_TOKEN:
      'public-token-test-f25e9090-7f54-47b5-855a-2ec505a66e38',
  },
  'juan-dev': {
    API_URL: 'https://api.juan-dev.scripto.live',
    NIDO_URL: 'https://nido.juan-dev.scripto.live',
    MIXPANEL_TOKEN: STAGING_MIXPANEL_TOKEN,
    SLACK_CLIENT_ID,
    LOG_LEVEL: 'info',
    STYTCH_PUBLIC_TOKEN:
      'public-token-test-e8aec51d-9278-40e5-bf99-62f0a9498ac0',
  },
  'boon-test': {
    API_URL: 'https://api.boon-test.scripto.live',
    NIDO_URL: 'https://nido.boon-test.scripto.live',
    MIXPANEL_TOKEN: STAGING_MIXPANEL_TOKEN,
    SLACK_CLIENT_ID,
    LOG_LEVEL: 'info',
    STYTCH_PUBLIC_TOKEN:
      'public-token-live-dbb4aadb-861a-44ac-aee2-73f555e07c51',
  },
  'alex-dev': {
    API_URL: 'https://api.alex-dev.scripto.live',
    NIDO_URL: 'https://nido.alex-dev.scripto.live',
    MIXPANEL_TOKEN: STAGING_MIXPANEL_TOKEN,
    SLACK_CLIENT_ID,
    LOG_LEVEL: 'info',
    HTTP_TIMEOUT_MS: 1_000,
    STYTCH_PUBLIC_TOKEN:
      'public-token-live-00c73ca7-c4b4-4dc5-a434-ac96277bd3be',
  },
}

const localProxyConfig = (environment: KnownHostedServer): ServerConfig => {
  const { API_URL, NIDO_URL, STYTCH_PUBLIC_TOKEN } =
    HOSTED_SERVER_CONFIGS[environment]
  return {
    ...LOCAL,
    API_URL,
    NIDO_URL,
    STYTCH_PUBLIC_TOKEN,
  }
}

const getConfig = (environment: KnownServer): ServerConfig => {
  if (environment === 'local') {
    return LOCAL
  }

  // process.env.NODE_ENV is set at build time by React
  if (process.env.NODE_ENV === 'development') {
    return localProxyConfig(environment)
  }

  return {
    ...HOSTED_SERVER_CONFIGS[environment],
    NODE_ENV: 'production',
  }
}

// if you're hitting from a known url like https://staging.scripto.dev
// or http://localhost:3004 this will find the valid hostname
const getKnownServerFromHostname = (
  hostname: string,
): KnownServer | undefined => {
  const hostPart = hostname.split('.')[0]
  if (isKnownServer(hostPart)) {
    return hostPart
  }
  if (hostPart === 'localhost') {
    return 'local'
  }
}

const getBuildTimeOverride = (): KnownServer | undefined => {
  const envValue = window.scriptoEnvironment
  if (typeof envValue === 'string' && isKnownServer(envValue)) {
    return envValue
  }
}

export const getServerConfig = (): NamedServerConfig => {
  const environment: KnownServer | undefined =
    getBuildTimeOverride() ??
    getKnownServerFromHostname(window.location.hostname)

  if (!environment) {
    if (process.env.NODE_ENV !== 'production') {
      throw new Error('Invalid server configuration')
    }

    ddLog.error('Scripto server environment misconfigured', {
      hostname: window.location.hostname,
      build: getBuildTimeOverride(),
    })
    // If we wind up in this situation, it's a bug or bad deployment, but
    // let's opt for production as the best possible fallback for end users
    return { ...getConfig('production'), name: 'production' }
  }

  return { ...getConfig(environment), name: environment }
}
