import React from 'react'

import { Button, Group, Select, Stack } from '@mantine/core'

import { showAlert, showError } from '@components/Modals'
import { useMst } from '@state'
import { parseServerError } from '@util'
import { WorkspaceDetailsPayload } from '@util/ScriptoApiClient/types'

import { StaffFormatSummary } from './util'

export function DefaultFormatsForm({
  org,
  formats,
}: {
  org: WorkspaceDetailsPayload
  formats: StaffFormatSummary[]
}) {
  const { apiClient } = useMst()
  const [studioFormatId, setStudioFormatId] = React.useState(
    org.studioFormat.id,
  )
  const [screenplayFormatId, setScreenplayFormatId] = React.useState(
    org.screenplayFormat.id,
  )

  const formatData = formats
    .map((f) => {
      const label = `${f.id} - ${f.name}`
      return { value: f.id, label, disabled: !f.active }
    })
    .sort((a, b) => a.value.localeCompare(b.value))

  const saveChanges = async () => {
    try {
      await apiClient.setScriptFormatsForOrg({
        orgId: org.id,
        data: {
          studioFormatId,
          screenplayFormatId,
        },
      })
      showAlert({
        title: 'Success',
        children: 'Default formats updated successfully',
      })
    } catch (e) {
      const { message } = parseServerError(e)
      showError({
        title: 'Error',
        message,
      })
    }
  }

  return (
    <Stack gap="sm">
      <br />
      <label>Studio Format</label>
      <Select
        data={formatData}
        value={studioFormatId}
        onChange={(value) => {
          if (typeof value === 'string') {
            setStudioFormatId(value)
          }
        }}
        w={400}
      />

      <label>Screenplay Format</label>

      <Select
        data={formatData}
        value={screenplayFormatId}
        onChange={(value) => {
          if (typeof value === 'string') {
            setScreenplayFormatId(value)
          }
        }}
        w={400}
      />

      <Group>
        <Button type="submit" onClick={saveChanges}>
          Save Changes
        </Button>
      </Group>
    </Stack>
  )
}
