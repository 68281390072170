import {
  Breadcrumbs as BreadcrumbsComponent,
  Space,
  Title,
} from '@mantine/core'

import { NavAnchor, NavAnchorHref } from '@components/NavAnchor'
import { ROUTE_PATTERNS } from '@util'

export const Breadcrumbs = ({
  crumbs = [],
}: {
  crumbs?: { text: string; url?: NavAnchorHref }[]
}) => {
  const links = [
    { text: 'Staff Zone', url: ROUTE_PATTERNS.staffLanding },
    ...crumbs,
  ].map((c) => {
    const title = <Title order={4}>{c.text}</Title>
    return c.url ? <NavAnchor href={c.url}>{title}</NavAnchor> : title
  })

  return (
    <>
      <BreadcrumbsComponent>{links}</BreadcrumbsComponent>
      <Space h={10} />
    </>
  )
}
