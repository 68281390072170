import { useState } from 'react'

import { Group, Select, Stack, Text } from '@mantine/core'

import { showAsyncConfirmModal } from '@components/Modals'
import { useMst } from '@state'
import { WorkspaceDetailsPayload } from '@util/ScriptoApiClient/types'

export const RundownSchemaForm = ({
  org,
  schemaNames = [],
}: {
  org: WorkspaceDetailsPayload
  schemaNames?: string[]
}) => {
  const { apiClient } = useMst()
  const [currentSchema, setCurrentSchema] = useState(org.rundownSchemaName)
  const handleChange = async (value: string | null) => {
    if (typeof value === 'string' && value !== currentSchema) {
      showAsyncConfirmModal({
        title: 'Change Rundown Schema',
        children: `Are you sure you want to change the rundown schema for ${org.name} to ${value}?`,
        onConfirm: async () => {
          await apiClient.setRundownSchemaForOrg({
            orgId: org.id,
            schemaName: value,
          })
          setCurrentSchema(value)
        },
        errorMessage: 'Failed to change schema',
      })
    }
  }
  return (
    <form className="s-form">
      <Stack style={{ marginTop: 20 }}>
        <Text>
          Configure the rundown schema used for FUTURE rundowns created in this
          workspace.
        </Text>
        <Text>
          NOTE: this does not add the rundowns feature to the org. You can do
          that in &quot;Plan Options&quot; above
        </Text>
        <Group>
          <Select
            data={schemaNames}
            value={currentSchema}
            onChange={handleChange}
          />
        </Group>
      </Stack>
    </form>
  )
}
