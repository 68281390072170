import { Fragment, useState } from 'react'

import { showAlert } from '@components/Modals'
import { useMst } from '@state'
import { extractTsRestSuccess } from '@util/extractTsRest'
import {
  WorkspaceDetailsPayload,
  WorkspaceTier,
} from '@util/ScriptoApiClient/types'

import { SHOW_TIERS } from '../../../choo-app/lib/helpers.js'

const LABELED_TIERS = [
  [SHOW_TIERS.FREE, 'Free'],
  [SHOW_TIERS.NEW_MEDIA, 'Small Budget / New Media'],
  [SHOW_TIERS.TV_FILM, 'Big Budget / TV & Film'],
]

function TierRadio({
  orgId,
  tier,
  isCurrent,
  onChange,
}: {
  orgId: string
  tier: string[]
  isCurrent: boolean
  onChange: (tier: WorkspaceTier) => void
}) {
  const { scrapi } = useMst()
  const tierName = tier[0]
  const tierLabel = tier[1]

  const changeTier = async (tier: WorkspaceTier) => {
    try {
      await extractTsRestSuccess(
        scrapi.staff.updateWorkspace({
          params: { id: orgId },
          body: { tier },
        }),
        200,
      )
      onChange(tier)
      showAlert({
        title: 'Success',
        children: 'Workspace tier updated successfully',
      })
    } catch (e) {
      showAlert({
        title: 'Error',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        children: (e as any).message,
      })
    }
  }

  return (
    <>
      <input
        className="s-input s-input--radio s-input--inline"
        type="radio"
        id={tierName}
        value={tierName}
        name="tier"
        onChange={() => changeTier(tierName as WorkspaceTier)}
        checked={isCurrent}
      />
      <label
        className="s-label s-label--radio s-label--inline"
        htmlFor={tierName}
      >
        {tierLabel}
      </label>
    </>
  )
}

export function TierForm({ org }: { org: WorkspaceDetailsPayload }) {
  const [tier, setTier] = useState(org.tier)
  return (
    <form className="s-form">
      <fieldset className="s-form__fieldset">
        {LABELED_TIERS.map((t) => (
          <Fragment key={t[0]}>
            <TierRadio
              tier={t}
              isCurrent={t[0] === tier}
              orgId={org.id}
              onChange={setTier}
            />
          </Fragment>
        ))}
      </fieldset>
    </form>
  )
}
