import { Modal as ModalComponent, ModalProps } from '@mantine/core'

import styles from './modal.module.scss'

// Note: 'none' for the variant is a bit of a hack here — it'd be nice to have
// <Modal variant={undefined} /> provide the default styles, but that would be
// overridden by the defaultProps later on where we set variant="standard"
// for existing modal compatibility

declare module '@mantine/core' {
  export interface ModalProps {
    /**
     * Modal variant styles:
     * - none: The default Mantine modal styles without customization
     * - compact: Remove body padding and vertical padding on the header
     * - standard: The standard Scripto modal with a thin grey header
     */
    variant?: 'none' | 'compact' | 'standard'
    /**
     * Prevent the modal's body from scrolling with a fixed height
     */
    'data-fixed-height'?: boolean
  }
}

const defaultProps: Partial<ModalProps> = {
  centered: true,
  variant: 'standard',

  closeOnClickOutside: false,
  // The overlay opacity transition takes half this time,
  // the modal body transition takes this full time
  transitionProps: {
    duration: 250,
  },
}

export const Modal = ModalComponent.extend({
  defaultProps,
  classNames: styles,
})
